<template>
  <v-container fluid>
    <router-view
      :headers="headers"
      :expanded-headers="expanded"
      :data="data"
      :loading="loading"
      :table-page="page"
      :table-page-total-count="pageTotalCount"
      :table-size="size"
      @change="loadData($event.page, $event.size)"
      @onSearch="onSearch"
    />
  </v-container>
</template>

<script>
import { getUrllistPredefined } from "@/api/datafeeds/urllist/predefined";

import { Errors } from "@/utils/error";

export default {
  data() {
    return {
      headers: [],
      expanded: [],
      data: [],
      loading: true,
      search: undefined,

      page: 1,
      pageTotalCount: 1,
      size: 25,
      sizeTotalCount: 0,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
    dataFeedsUrlListPredefinedAllTable: {
      get() {
        return this.$store.state.tables.dataFeedsUrlListPredefinedAllTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "dataFeedsUrlListPredefinedAllTable",
          value: val,
        });
      },
    },
  },
  watch: {
    dataFeedsUrlListPredefinedAllTable: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          const self = this;
          this.headers = [];
          newValue.headers.forEach(function (header) {
            if (header.visible) {
              self.headers.push(header);
            }
          });
          this.expanded = [];
          newValue.expanded.forEach(function (item) {
            if (item.visible) {
              self.expanded.push(item);
            }
          });
        }
      },
    },
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "predefinedurllist") {
          this.loadData(this.page, this.size);
        }
        this.changeInAddForm = "";
      },
    },
  },
  created() {
    this.loadData(this.page, this.size);
  },
  methods: {
    loadData(page, size) {
      this.loading = true;
      let newPage = page;
      if (size !== this.size) {
        newPage = 1;
      }
      getUrllistPredefined(newPage, size, this.search)
        .then((response) => {
          this.loading = false;
          this.data = response.data;
          this.pageTotalCount = response.last_page;
          this.page = response.current_page;
          this.size = parseInt(response.per_page);
          this.sizeTotalCount = response.total;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    onSearch(event) {
      this.search = event;
      this.page = 1;
      this.loadData(this.page, this.size);
    }
  },
};
</script>
